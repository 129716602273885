<template>
  <div class="bg-white">
    <div
      class="bg-white border mb-4"
      style="
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        padding: 48px;
      "
    >
      <!-- <FBconfirmModal
        v-if="isPaymentModalActiveCancel"
        :order-i-d="selectedOrderId"
        @setIsPaymentModalActiveCancel="setIsPaymentModalActiveCancel"
      /> -->
      <label class="col-12 pe-2 mb-3 fs-4 fw-bold">基本資料</label>
      <hr>
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center g-0"
      >
        <!-- 聯絡手機 -->
        <label
          class="col-12 my-2 fw-bold"
        >聯絡手機 : </label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-12 text-start">
            {{ memberDetail.cell || '-' }}
          </div>
        </div>

        <!-- 電子信箱 -->
        <label class="col-12 pe-2 my-2 fw-bold">電子信箱：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-12 text-start">
            {{ memberDetail.email || '-' }}
          </div>
        </div>

        <!-- Facebook公開名稱 -->
        <label class="col-12 my-2 fw-bold">Facebook公開名稱 <span :class="$style.required">*</span>：</label>
        <div class="col-12 mb-4 d-flex">
          <Input
            id="showName"
            v-model:input="state.showName"
            type="text"
            direction="column"
            class="flex-grow-1"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入Facebook公開名稱"
            :err-message="v$.showName?.$errors[0]?.$message || ''"
            @focus="v$.showName.$touch"
            @enter="updateMemberDetail"
          />
        </div>

        <!-- 會員真實姓名 -->
        <label
          class="col-12 my-2 fw-bold"
        >會員真實姓名 <span :class="$style.required">*</span>：</label>
        <div class="col-12 mb-4 d-flex">
          <Input
            id="lastName"
            v-model:input="state.lastName"
            type="text"
            class="me-2 flex-grow-1"
            direction="column"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入姓氏"
            :err-message="v$.lastName?.$errors[0]?.$message || ''"
            @focus="v$.lastName.$touch"
            @enter="updateMemberDetail"
          />
          <Input
            id="firstName"
            v-model:input="state.firstName"
            type="text"
            class="flex-grow-1"
            direction="column"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入名字"
            :err-message="v$.firstName?.$errors[0]?.$message || ''"
            @focus="v$.firstName.$touch"
            @enter="updateMemberDetail"
          />
        </div>

        <!-- birthday -->
        <label
          class="col-12 my-2 fw-bold"
        >會員生日 <span :class="$style.required">*</span>：</label>
        <InputDatePicker
          v-model:input="state.birthday"
          class="bg-transparent mb-4 p-0"
          :class="{ 'border-danger': v$.birthday?.$error }"
          :input-format="'yyyy-MM-dd'"
          :clearable="false"
          placeholder="請輸入生日"
          :err-message="v$.birthday?.$errors[0]?.$message || ''"
          :style="{
            '--vdp-selected-bg-color': '#cbbbae',
            '--vdp-hover-bg-color': '#d4c4b7'
          }"
          @focus="v$.birthday.$touch"
        />

        <!-- 縣市 -->
        <label
          class="col-12 my-2 fw-bold"
        >會員縣市 <span :class="$style.required">*</span>：</label>
        <div class="col-12 mb-4 d-flex flex-column position-relative">
          <select
            id="county"
            v-model="state.county"
            class="form-select flex-grow-1"
            :class="[{'border-danger': v$.county?.$error}]"
            placeholder="請輸入縣市"
            @focus="v$.county.$touch"
          >
            <option
              :value="''"
              disabled="disabled"
            >
              請輸入縣市
            </option>
            <option
              v-for="city in cities"
              :key="city.CityName"
              :value="city.CityName"
            >
              {{ city.CityName }}
            </option>
          </select>
          <div class="d-flex position-relative">
            <small
              class="text-danger position-absolute start-0 error-text"
              :class="[{'show': v$.county?.$error}]"
            >{{ v$.county?.$errors[0]?.$message || '' }}</small>
          </div>
        </div>

        <!-- 會員地址 -->
        <label
          class="col-12 my-2 fw-bold"
        >會員地址 <span :class="$style.required">*</span>：</label>
        <div class="col-12 mb-4 d-flex">
          <Input
            id="address"
            v-model:input="state.address"
            type="text"
            class="flex-grow-1"
            direction="column"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入地址"
            :err-message="v$.address?.$errors[0]?.$message || ''"
            @focus="v$.address.$touch"
            @enter="updateMemberDetail"
          />
        </div>
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 pe-2 my-2 fw-bold">聯絡手機：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.cell || '-' }}
          </div>
        </div>
        <label class="col-12 pe-2 my-2 fw-bold">電子信箱：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-12 text-start">
            {{ memberDetail.email || '-' }}
          </div>
        </div>
        <label class="col-4 pe-2 my-2 fw-bold">Facebook公開名稱：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.showName || '-' }}
          </div>
        </div>
        <label class="col-4 pe-2 my-2 fw-bold">會員真實姓名：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.lastName || '-' }}
            {{ memberDetail.firstName || '' }}
          </div>
        </div>
        <label class="col-4 pe-2 my-2 fw-bold">會員生日：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.birthday || '-' }}
          </div>
        </div>
        <label class="col-4 pe-2 my-2 fw-bold">會員縣市：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.county || '-' }}
          </div>
        </div>
        <label class="col-4 pe-2 my-2 fw-bold">會員地址：</label>
        <div class="row mb-4 align-items-center g-0">
          <div class="col-8 text-start">
            {{ memberDetail.address || '-' }}
          </div>
        </div>
      </div>

      <div
        v-if="isEdit"
        class="d-flex justify-content-center"
      >
        <button
          class="btn btn-outline-primary flex-grow-1 me-2"
          type="button"
          style="border-radius: 100px; width: 231px; height: 52px"
          @click="toggleEdit"
        >
          取消
        </button>
        <button
          class="btn btn-primary text-white flex-grow-1"
          type="button"
          style="border-radius: 100px; width: 231px; height: 52px"
          @click="updateMemberDetail"
        >
          儲存
        </button>
      </div>
      <div
        v-else
        class="d-flex"
      >
        <button
          class="btn btn-primary text-white flex-grow-1"
          type="button"
          style="border-radius: 100px; width: 231px; height: 52px"
          @click="toggleEdit"
        >
          編輯
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import cities from '@/hooks/cities.json'
import Input from '@/components/Input.vue'
// import Datepicker from 'vue3-datepicker'
import useVuelidate from '@vuelidate/core'
import {
  requiredHelper,
  emailHelper,
  mobileHelper,
  numericHelper
} from '@/hooks/useVuelidate.js'
import { useStore } from 'vuex'
import { computed, ref, inject } from 'vue'
import { useErrorMessage } from '@/hooks/errorMessage.js'
import InputDatePicker from '@/components/InputDatePicker'
// import { useFacebook } from '@/hooks/fb.js'
// import FBconfirmModal from '@/components/FBconfirmModal'

export default {
  name: 'MemberDetail',
  components: {
    Input, InputDatePicker
    // FBconfirmModal
  },
  async setup (props) {
    // const { initFacebookButton } = useFacebook()
    const { getters, dispatch } = useStore()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()
    // const fbIDToken = computed(() => getters['validate/getFbIDToken'])

    const memberID = computed(() => getters['member/getMemberID'])
    const memberDetail = computed(() => getters['member/getMemberDetail'])

    const setAlert = inject('setAlert')

    const isPaymentModalActiveCancel = ref(false)

    const state = ref({
      showName: memberDetail.value.showName || '',
      lastName: memberDetail.value.lastName || null,
      firstName: memberDetail.value.firstName || null,
      gender: memberDetail.value.gender || '',
      birthday: memberDetail.value.birthday
        ? new Date(memberDetail.value.birthday)
        : null,
      cell: memberDetail.value.cell || null,
      email: memberDetail.value.email || '',
      phone: memberDetail.value.phone || '',
      county: memberDetail.value.county || '',
      address: memberDetail.value.address || ''
    })

    const rules = {
      showName: { requiredHelper },
      lastName: { requiredHelper },
      firstName: { requiredHelper },
      gender: {},
      birthday: { requiredHelper },
      cell: { mobileHelper },
      email: { emailHelper },
      phone: { numericHelper },
      county: { requiredHelper },
      address: { requiredHelper }
    }

    const v$ = useVuelidate(rules, state)

    const resetState = () => {
      state.value = {
        showName: memberDetail.value.showName || '',
        lastName: memberDetail.value.lastName || null,
        firstName: memberDetail.value.firstName || null,
        gender: memberDetail.value.gender || '',
        birthday: memberDetail.value.birthday
          ? new Date(memberDetail.value.birthday)
          : null,
        cell: memberDetail.value.cell || null,
        email: memberDetail.value.email || '',
        phone: memberDetail.value.phone || '',
        county: memberDetail.value.county || '',
        address: memberDetail.value.address || ''
      }
    }

    // const showFacebookBindButton = computed(() => {
    //   const responseAccount = memberDetail.value.accounts
    //   let returnValue = true
    //   responseAccount.forEach((item) => {
    //     if (item.type === 'facebook') {
    //       returnValue = false
    //     }
    //   })
    //   return returnValue
    // })

    // const facebookOnLoginIcon = () => {
    //   window.FB.login(function (response) {
    //     const fbAccess = response.authResponse?.accessToken
    //     readHasSignUp(fbAccess)
    //   })
    // }

    // const facebookOnLogin = () => {
    //   window.FB.getLoginStatus(function (response) {
    //     const fbAccess = response.authResponse?.accessToken
    //     readHasSignUp(fbAccess)
    //   })
    // }

    // const facebookOnLogin = () => {
    //   window.FB.getLoginStatus(function (response) {
    //     const fbAccess = response.authResponse?.accessToken
    //     commit('validate/setFbIDToken', { data: fbAccess })
    //   })
    //   window.setTimeout(
    //     () =>
    //       (isPaymentModalActiveCancel.value =
    //         !isPaymentModalActiveCancel.value),
    //     1000
    //   )
    // }

    // const readHasSignUp = async (fbAccess) => {
    //   const payload = {
    //     memberID: memberID.value,
    //     id_token: fbAccess,
    //     sso_type: 'facebook'
    //   }

    //   try {
    //     const response = await dispatch('member/createFacebookIDSSO', {
    //       payload
    //     })

    //     if (response.data.errMsg) {
    //       const errorCode = response.data.errMsg.split(':')[0]
    //       if (errorCode === '404-12') {
    //         push('/signup')
    //       }
    //       setAlert(true, false, getErrorMessage(response))
    //     } else {
    //       push('/')
    //       setAlert(true, true, '已成功和FB帳號綁定。')
    //     }
    //     return response
    //   } catch (error) {
    //     // setAlert(true, false, `登入失敗: ${error}`)
    //   }
    // }

    // console.warn('initFacebook')
    // initFacebook()
    // onMounted(() => {
    // window.facebookOnLoginIcon = facebookOnLoginIcon
    // initFacebookButton()
    // })

    const getThousands = (num) => {
      return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    }

    const isEdit = ref(false)
    const toggleEdit = () => {
      // reset when edit will be set true
      if (!isEdit.value) {
        resetState()
        v$.value.$reset()
      }
      isEdit.value = !isEdit.value
    }

    const updateMemberDetail = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        toggleEdit()
        const payload = {
          memberID: memberID.value,
          ...state.value
        }
        if (new Date(payload.birthday).getTime() !== 0) {
          payload.birthday = `${new Date(payload.birthday).getFullYear()}-${
            new Date(payload.birthday).getMonth() + 1
          }-${new Date(payload.birthday).getDate()}`
        } else {
          payload.birthday = ''
        }
        try {
          const response = await dispatch('member/updateMemberDetail', {
            payload
          })
          await dispatch('member/readMemberDetail', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
          }

          v$.value.$reset()
          return response
        } catch (error) {
          // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, `更新失敗: ${error}`)
          v$.value.$reset()
        }
      }
    }

    const readMemberDetail = async () => {
      const payload = { memberID: memberID.value }

      try {
        await dispatch('member/readMemberDetail', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const setIsPaymentModalActiveCancel = () => {
      isPaymentModalActiveCancel.value = !isPaymentModalActiveCancel.value
    }

    await readMemberDetail()

    return {
      memberDetail,
      isEdit,
      toggleEdit,
      state,
      v$,
      updateMemberDetail,
      getThousands,
      cities,
      memberID,
      setIsPaymentModalActiveCancel,
      isPaymentModalActiveCancel
    }
  }
}
</script>
<style lang="scss" module>
.required {
  color: $red-200;
}
</style>
